import { matchPath, useLocation } from 'react-router-dom';
import {
  pathDashboard,
  pathLogout,
  pathMfaLogin,
  pathOnboarding,
} from '../constants/path';

const avoidRedirectPaths = [pathMfaLogin(), pathLogout(), pathOnboarding()];

export function useRedirectAfterLogin() {
  const location = useLocation();
  const stateValue = location.state?.redirectAfterLogin;
  const defaultValue = {
    pathname: pathDashboard(),
  };
  if (!stateValue) {
    return defaultValue;
  }
  if (avoidRedirectPaths.some((path) => matchPath(path, stateValue.pathname))) {
    return defaultValue;
  }
  return stateValue;
}
