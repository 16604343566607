import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Navigate,
  Route,
  Outlet,
} from 'react-router-dom';
import { Box, Flex, Heading, Spinner } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';
import { lazy, Suspense } from 'react';
import {
  ForgotPassword,
  Login,
  ResetPassword,
  VerifyMFA,
} from '../../components/Auth';
import LayoutAuthenticated, {
  AuthenticatedOrgContext,
} from '../../components/LayoutAuthenticated/LayoutAuthenticated';
import {
  pathCookies,
  pathDashboard,
  pathForbidden,
  pathForgotPassword,
  pathLogin,
  pathLogout,
  pathMfaLogin,
  pathOnboarding,
  pathResetPassword,
  pathSettings,
  pathSites,
  pathSitesEdit,
  pathSitesManage,
  pathSitesOverviewLegacy,
  pathSitesOverview,
  pathSitesPlan,
  pathSitesPlanLegacy,
  pathSitesView,
  PlanInsightType,
  pathTeamSettings,
  pathSitesCreate,
  pathRouteStatus,
  pathUnsubscribe,
  pathSiteVmsBoards,
  pathRoutePollStatus,
  pathImpactAssessmentRequest,
  pathVmsBoard,
} from '../../constants/path';
import useParamInsightId from '../../hooks/useParamInsightId';

import ErrorPage from '../../components/ErrorPage';
import Logout from '../../components/Auth/Logout';
import RouteErrorBoundary from '../../components/RouteErrorBoundary';
import { envConfig, getEnvironment } from '../../config/envConfig';
import { LayoutSmartVmsPageWrapper } from '../../components/SmartVms/LayoutSmartVms';
import { RouteDetails } from '../../components/LiveNetworkMonitoring/RouteDetails';
import { SiteContextProvider } from '../../hooks/useSiteView';
import SiteUserViewDefaultSelector from '../../components/SiteView/SiteUserViewDefaultSelector';
import { WithProfiler } from './with-profiler';
import {
  pathOrg,
  pathManagement,
  pathSiteMonitoringPlanning,
  pathSiteMonitoringPerformance,
  pathSiteMonitoringEdit,
  pathSiteMonitoringVms,
  pathManagementProject,
  pathReporting,
  pathOrgSettings,
  pathOrgTeamSettings,
  pathOrgUserSettings,
  pathOperationsHubRoute,
  pathOperationsHub,
  pathSiteMonitoring,
  pathOrgTeamFragmentSettings,
  pathSiteMonitoringIndex,
  pathOrgSettingsAccount,
} from '../../router/utils/path';
import { ConfiguredSWRWrapper } from '../../config/swr';

const ContextAuth = lazy(() => import('../../router/context/ContextAuth'));
const ContextOrg = lazy(() => import('../../router/context/ContextOrg'));
const LayoutOrg = lazy(() => import('../../router/layout/LayoutOrg'));
const PageOrg = lazy(() => import('../../router/pages/org'));
const PageLiveNetwork = lazy(
  () => import('../../router/pages/org/live-network')
);
const LayoutManagement = lazy(
  () => import('../../router/layout/LayoutManagement')
);
const PageManagement = lazy(() => import('../../router/pages/org/management'));
const LayoutSite = lazy(() => import('../../router/layout/LayoutSite'));
const LayoutSitePerformance = lazy(
  () => import('../../router/layout/LayoutSitePerformance')
);
const PageReporting = lazy(() => import('../../router/pages/org/reporting'));
const PageSettings = lazy(() => import('../../router/pages/org/settings'));
const PageSitePlanning = lazy(
  () => import('../../router/pages/org/site-monitoring/site/site-planning')
);
const PageCurrentPerformance = lazy(
  () =>
    import('../../router/pages/org/site-monitoring/site/current-performance')
);
const PagePublicTransport = lazy(
  () =>
    import(
      '../../router/pages/org/site-monitoring/site/current-performance/public-transport'
    )
);
const PageSmartVms = lazy(
  () => import('../../router/pages/org/site-monitoring/site/smart-vms')
);
const PageVmsBoard = lazy(
  () => import('../../router/pages/org/site-monitoring/site/smart-vms/board')
);
const PageEditSite = lazy(
  () => import('../../router/pages/org/site-monitoring/site/edit-site')
);
const ContextProject = lazy(
  () => import('../../router/context/ContextProject')
);
const PageProject = lazy(
  () => import('../../router/pages/org/management/project')
);
const ContextSite = lazy(() => import('../../router/context/ContextSite'));
const ContextVMS = lazy(() => import('../../router/context/ContextVMS'));
const ContextRoute = lazy(() => import('../../router/context/ContextRoute'));
const LayoutLiveNetworkMonitoring = lazy(
  () => import('../../router/layout/LayoutLiveNetworkMonitoring')
);
const PageSiteMonitoring = lazy(
  () => import('../../router/pages/org/site-monitoring')
);
const LayoutSettings = lazy(() => import('../../router/layout/LayoutSettings'));
const PageUserSettings = lazy(
  () => import('../../router/pages/org/settings/PageUserSettings')
);
const PageTeamSettings = lazy(
  () => import('../../router/pages/org/settings/PageTeamSettings')
);
const LayoutSiteMonitoringIndex = lazy(
  () => import('../../router/layout/LayoutSiteMonitoringIndex')
);
const PageAccountSettings = lazy(
  () => import('../../router/pages/org/settings/PageAccountSettings')
);
const LayoutHeader = lazy(() => import('../../components/LayoutHeader'));
const ReportHeader = lazy(
  () => import('../../router/pages/org/reporting/ReportHeader')
);
const RedirectLegacySiteUrl = lazy(() => import('./RedirectSiteUrl'));
const PageSiteMonitoringSite = lazy(
  () => import('../../router/pages/org/site-monitoring/site')
);

const Onboarding = lazy(() => import('../../components/Onboarding'));
const PlanVehicleCount = lazy(
  () => import('../../components/PlanVehicleCount')
);
const Unsubscribe = lazy(() => import('../../components/Unsubscribe'));
const ImpactAssessmentRequest = lazy(
  () => import('../../components/ImpactAssessmentRequest')
);
const RoutePollingStatus = lazy(
  () => import('../../components/RoutePollingStatus')
);
const SiteView = lazy(() => import('../../components/SiteView'));
const Plan = lazy(() => import('../../components/SitePlan'));
const SiteCreate = lazy(() => import('../../components/SiteCreate'));
const EditSite = lazy(() => import('../../components/SiteEdit/EditSite'));
const EditableSites = lazy(() => import('../../components/EditableSites'));
const HomePage = lazy(() => import('../../components/HomePage'));
const TeamSettings = lazy(() => import('../../components/TeamSettings'));
const UserProfile = lazy(() => import('../../components/UserProfile'));
const SmartVms = lazy(() => import('../../components/SmartVms'));
const SmartVmsCreateBoard = lazy(
  () => import('../../components/SmartVms/SmartVmsCreateBoard')
);
const PlanningTaskList = lazy(
  () => import('../../pages/programme-view/PlanningTaskList')
);
const ProjectsListPage = lazy(
  () => import('../../pages/programme-view/pages/ProjectsListPage')
);
const ProjectPage = lazy(
  () => import('../../pages/programme-view/pages/ProjectPage')
);

export default createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        getEnvironment() !== 'production' ? (
          <WithProfiler profilerId="root" />
        ) : (
          <Outlet />
        )
      }
    >
      <Route element={<AppWrappers />} errorElement={<RouteErrorBoundary />}>
        <Route path="/" element={<Navigate to={pathDashboard()} replace />} />
        <Route
          path={pathCookies()}
          element={
            <ErrorPage
              text="Please enable cookies to view this website"
              noLink
            />
          }
        />
        <Route
          path={pathForbidden()}
          element={<ErrorPage text="403 Forbidden" />}
        />

        <Route path={pathLogin()} element={<Login />} />

        <Route element={<ContextAuth userRequired={false} />}>
          <Route path={pathUnsubscribe()} element={<Unsubscribe />} />
          <Route
            path={pathRoutePollStatus()}
            element={<RoutePollingStatus />}
          />
          <Route path={pathForgotPassword()} element={<ForgotPassword />} />
          <Route path={pathResetPassword()} element={<ResetPassword />} />
        </Route>

        <Route element={<ContextAuth onboarding />}>
          <Route path={pathOnboarding()} element={<Onboarding />} />
        </Route>

        <Route element={<ContextAuth />}>
          <Route element={<RedirectLegacySiteUrl />}>
            <Route path={pathMfaLogin()} element={<VerifyMFA />} />

            <Route element={<ContextOrg />}>
              <Route element={<LayoutOrg />} path={pathOrg()}>
                <Route index element={<PageOrg />} />
                <Route element={<ContextProject />}>
                  <Route element={<LayoutManagement />}>
                    <Route
                      path={pathManagement()}
                      element={<PageManagement />}
                    />
                    <Route
                      path={pathManagementProject()}
                      element={<PageProject />}
                    />
                  </Route>
                </Route>
                <Route element={<ContextRoute />}>
                  <Route element={<LayoutLiveNetworkMonitoring />}>
                    <Route
                      path={pathOperationsHub()}
                      element={<PageLiveNetwork />}
                    />
                    <Route
                      path={pathOperationsHubRoute()}
                      element={<RouteDetails />}
                    />
                  </Route>
                </Route>
                <Route element={<LayoutSiteMonitoringIndex />}>
                  <Route
                    path={pathSiteMonitoringIndex()}
                    element={<PageSiteMonitoring />}
                  />
                </Route>
                <Route element={<ContextSite />}>
                  <Route element={<LayoutSite />} path={pathSiteMonitoring()}>
                    <Route index element={<PageSiteMonitoringSite />} />
                    <Route
                      path={pathSiteMonitoringPlanning()}
                      element={<PageSitePlanning />}
                    />
                    <Route
                      path={pathSiteMonitoringPerformance()}
                      element={<LayoutSitePerformance />}
                    >
                      <Route index element={<PageCurrentPerformance />} />
                      <Route
                        path={`${pathSiteMonitoringPerformance()}/public-transport`}
                        element={<PagePublicTransport />}
                      />
                    </Route>
                    <Route
                      path={pathSiteMonitoringEdit()}
                      element={<PageEditSite />}
                    />
                    <Route
                      path={pathSiteMonitoringVms()}
                      element={<ContextVMS newNavigation />}
                    >
                      <Route index element={<PageSmartVms />} />
                      <Route path={pathVmsBoard()} element={<PageVmsBoard />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <LayoutHeader header={<ReportHeader />}>
                      <Outlet />
                    </LayoutHeader>
                  }
                >
                  <Route path={pathReporting()} element={<PageReporting />} />
                </Route>
                <Route element={<LayoutSettings />}>
                  <Route path={pathOrgSettings()} element={<PageSettings />} />
                  <Route
                    path={pathOrgSettingsAccount()}
                    element={<PageAccountSettings />}
                  />
                  <Route
                    path={pathOrgTeamSettings()}
                    element={<PageTeamSettings />}
                  />
                  <Route
                    path={pathOrgTeamFragmentSettings()}
                    element={<PageTeamSettings />}
                  />
                  <Route
                    path={pathOrgUserSettings()}
                    element={<PageUserSettings />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path={pathLogout()} element={<Logout />} />
            <Route element={<ContextOrg />}>
              <Route element={<PlanningTaskList />}>
                <Route
                  path="/programme-view/:insightId"
                  element={<ProjectsListPage />}
                />
                <Route
                  path="/programme-view/:insightId/programme/:programmeId/projects/:projectId"
                  element={<ProjectPage />}
                />
              </Route>
            </Route>
            <Route path={pathRouteStatus()} element={<RoutePollingStatus />} />

            <Route element={<AuthenticatedOrgContext />}>
              <Route path={pathDashboard()} />
              <Route element={<ContextOrg />}>
                <Route element={<ContextSite />}>
                  <Route
                    path={pathSiteVmsBoards()}
                    element={<ContextVMS newNavigation={false} />}
                  >
                    <Route
                      index
                      element={
                        <LayoutSmartVmsPageWrapper Component={SmartVms} />
                      }
                    />
                    <Route
                      path={pathVmsBoard()}
                      element={
                        <LayoutSmartVmsPageWrapper
                          Component={SmartVmsCreateBoard}
                        />
                      }
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                path={pathImpactAssessmentRequest()}
                Component={ImpactAssessmentRequest}
              />

              <Route element={<LayoutAuthenticated showProjectSelector />}>
                <Route path={pathTeamSettings()} element={<TeamSettings />} />
                <Route
                  path={pathSitesOverviewLegacy()}
                  element={
                    <RedirectWithInsightId
                      pathBuilder={(insightId) =>
                        pathSitesOverview(insightId, 'sites', null)
                      }
                    />
                  }
                />

                <Route path={pathSitesOverview()} element={<HomePage />} />
                <Route element={<ContextOrg />}>
                  <Route path={pathSitesManage()} element={<EditableSites />} />
                </Route>
              </Route>

              <Route
                element={<LayoutAuthenticated showProjectSelector={false} />}
              >
                <Route path={pathSitesCreate()} element={<SiteCreate />} />
                <Route
                  path={pathSites()}
                  element={
                    <RedirectWithInsightId
                      pathBuilder={pathSitesOverviewLegacy}
                    />
                  }
                />
                <Route
                  element={
                    <SiteContextProvider>
                      <SiteUserViewDefaultSelector>
                        <Outlet />
                      </SiteUserViewDefaultSelector>
                    </SiteContextProvider>
                  }
                >
                  <Route element={<ContextOrg />}>
                    <Route path={pathSitesEdit()} element={<EditSite />} />
                  </Route>
                  <Route path={pathSitesView()} element={<SiteView />} />
                  <Route path={pathSitesPlan()} element={<Plan />} />
                  <Route
                    path={pathSitesPlan(undefined, PlanInsightType.COUNT)}
                    element={<PlanVehicleCount />}
                  />
                  <Route
                    path={pathSitesPlanLegacy()}
                    element={
                      <RedirectWithInsightId
                        pathBuilder={(insightId) =>
                          pathSitesPlan(insightId, PlanInsightType.TIME)
                        }
                      />
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route
              element={<LayoutAuthenticated showProjectSelector={false} />}
            >
              <Route path={pathSettings()} element={<UserProfile />} />
              <Route
                path={'/*'}
                element={
                  <Box textAlign="center" px={12} py={20}>
                    <Heading>
                      <FormattedMessage
                        defaultMessage="404 not found"
                        id="Cu34ak"
                        description="Page not found message"
                      />
                    </Heading>
                    <Link to="/">
                      <FormattedMessage
                        defaultMessage="Go to homepage"
                        id="bkWXbu"
                        description="link to homepage from error page"
                      />
                    </Link>
                  </Box>
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

function AppWrappers() {
  return (
    <ConfiguredSWRWrapper>
      <IntercomProvider appId={envConfig.INTERCOM_APP}>
        <Suspense
          fallback={
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              p={10}
            >
              <Spinner variant="mooven" size="xl" />
            </Flex>
          }
        >
          <Outlet />
        </Suspense>
      </IntercomProvider>
    </ConfiguredSWRWrapper>
  );
}

function RedirectWithInsightId({
  pathBuilder,
}: {
  pathBuilder: (insightId: string) => string;
}) {
  const insightId = useParamInsightId();
  return <Navigate to={pathBuilder(insightId)} replace />;
}
