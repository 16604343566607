import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import FormField from 'design-system/molecules/form-field';
import { resetPassword } from '../../api/auth';
import { QS_RESET_PASSWORD_TOKEN_KEY } from '../../constants/clientConstants';
import { pathLogin } from '../../constants/path';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useRedirectAfterLogin } from '../../hooks/useRedirectAfterLogin';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import { errorReport } from '../../utils/errors';

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default function ResetPassword() {
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();
  const redirectAfterLogin = useRedirectAfterLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState<string | null>(null);
  const {
    register,
    getValues,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const resetToken = params.get(QS_RESET_PASSWORD_TOKEN_KEY);
    if (!resetToken) {
      navigate(pathLogin(), { state: { redirectAfterLogin } });
    } else {
      setToken(resetToken);
    }
  }, [location.search, navigate, redirectAfterLogin]);

  const checkConfirmPassword = (
    confirmPassword: FormValues['confirmPassword']
  ) => {
    const formValues = getValues();
    if (confirmPassword && confirmPassword !== formValues.password) {
      return false;
    }
    return true;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      if (token) {
        await resetPassword(token, values.password);
        successToast({
          title: formatMessage({
            defaultMessage:
              'You have successfully changed your password. You may now log in.',
            id: 'hHRNqR',
            description: 'Successful password reset toast message',
          }),
        });
        navigate(pathLogin());
      }
    } catch (e) {
      errorReport.critical(e);
      failureToast({
        title: formatMessage({
          defaultMessage:
            'There was an error when trying to reset your password. Please try again later or contact support.',
          id: 'iZcQPe',
          description: 'Reset password error message',
        }),
      });
    }
  };

  return (
    <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
      <Box width={{ sm: '768px' }} m={8}>
        <Box w="150px" h="33px">
          <MoovenLogo />
        </Box>
        <Heading size="4xl" mb={4}>
          <FormattedMessage
            defaultMessage="Reset your password"
            id="qV44Xi"
            description="Reset password header"
          />
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={10}>
            <Text fontSize="2xl">
              <FormattedMessage
                defaultMessage="Set a new password"
                id="RWFnYe"
                description="Reset password instructions"
              />
            </Text>
          </Box>
          <FormField
            mb={4}
            isInvalid={!!errors.password}
            errorText={errors.password?.message}
            helperText={formatMessage({
              defaultMessage:
                'Must have at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your email.',
              id: 'Mq+9D/',
              description: 'create account password input helper text',
            })}
          >
            <Input
              type="password"
              autoComplete="new-password"
              placeholder={formatMessage({
                defaultMessage: 'Password',
                id: 'YXIKM7',
                description: 'Password email input placeholder',
              })}
              aria-label="password"
              {...register('password', {
                required: formatMessage({
                  defaultMessage: 'Please enter password',
                  id: 'TIrT2w',
                  description: 'Error message for password',
                }),
              })}
            />
          </FormField>
          <FormField
            mb={8}
            isInvalid={!!errors.confirmPassword}
            errorText={
              errors.confirmPassword?.type === 'checkConfirmPassword'
                ? formatMessage({
                    defaultMessage: 'Please make sure your password matches',
                    id: 'ElIH9F',
                    description: 'Error message for confirm password',
                  })
                : errors.confirmPassword?.message
            }
          >
            <Input
              type="password"
              autoComplete="new-password"
              placeholder={formatMessage({
                defaultMessage: 'Confirm password',
                id: 'ypxIsX',
                description: 'Confirm password email input placeholder',
              })}
              aria-label="confirm-password"
              {...register('confirmPassword', {
                required: formatMessage({
                  defaultMessage: 'Please enter confirm password',
                  id: 'GJYY+y',
                  description: 'Error message for confirm password',
                }),
                validate: {
                  checkConfirmPassword,
                },
              })}
            />
          </FormField>
          <HStack mb={10} spacing={4}>
            <Button
              isLoading={isSubmitting}
              variant="solid"
              colorScheme="brand"
              type="submit"
              isDisabled={!isValid || isSubmitting}
            >
              <FormattedMessage
                defaultMessage="Reset password"
                id="WQ6hYF"
                description="Reset password action"
              />
            </Button>
          </HStack>
        </form>
      </Box>
    </Flex>
  );
}
